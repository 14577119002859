import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src="https://katjuell-site.nyc3.cdn.digitaloceanspaces.com/computer_coffee.jpg" alt="" />
          </span>
          <p>
            Languages are the tool we use to make worlds. As an academic, I wrote about that fact; as a developer, I use it.
          </p>
          <p>
          Welcome to my website.
          </p>
          <p>
          In the past few years I have worked on many independent projects alongside my formal education. 
          My goal is to develop innovative solutions in the multimedia and automation industries through
          my combined software and hardware experience.
          </p>
          {close}
        </article>




        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Work</h2>
          <span className="image main">
            <img src="https://katjuell-site.nyc3.cdn.digitaloceanspaces.com/open_book.jpg" alt="" />
          </span>
          <p>My goal is to develop innovative solutions in the multimedia and automation industries through my combined software and hardware experience. </p>

          <p><b>Video Making Automation c/c++</b></p>
          <p>A C/C++ program to make a video from images.</p>

          <p>The goal is to write program that make a video from images. in place of human manual labour. This means a partially-autonomous editing workflow, where the human must strategically use the toolkit of programs to achieve their desired results. My rough personal guess is that when implemented correctly, this could save about 80% of my time spent editing.</p>

 
          <p><b>BATTERY TECHNOLOGY</b></p>
          <p>Custom design of low power protection circuits that are scalable and cheap, for 18650 cells</p>

          <p>Lithium battery technology is widely used in mobile devices today because of its high energy density. The same cells used in laptop batteries can be configured to power cars, houses, virtually anything. The only problem is that every battery pack has custom protection circuits made for its specific configuration. In order to assemble my own battery packs, I must create these circuits myself. Therefore, I made a discharge protection design using only low level components that is easily scalable for any configuration I want to make (just change one resistor!). As well, my circuit has an ultra low current consumption of 30μA max, and an auto shutoff for ZERO current draw in the “off” state.</p>

        
          <p><b>CONTRIBUTE</b></p>
          <p>If any aspects of this project interest you, contact me for more details, a detailed schematic, or to participate!</p>
          <p><b>Contact me!</b></p>
          {close}
        </article>








        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >samdingore.now.sh
          <h2 className="major">About</h2>
          <span className="image main">
            <img src="https://samdingorestorage.now.sh/0110/8add27f8-b1aa-4cfb-9f24-a85b067cd285.jpeg" alt="" />
          </span>
          <p>I am an electronics hobbyist, digital content creator, and a second year computer science student at the Vidyalankar Polytechnic.I was never the best at school or academics, I’m just a super curious guy. My channel exists to share these ideas and theories that interest me. I spend a lot of time building software and electronics, but there’s also a lot more to it.</p>
          <p>I created this site to share my interests, as they evolve, and connect with like minded individuals.</p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <p><b>Contact me with any suggestions, collaborations, or just to talk!</b></p>
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/SamDingore"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/sam-dingore-b97914192" className="icon fa-linkedin">
                <span className="label">LinkedIn</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/SamDingore"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/samdingore"
                className="icon fa-youtube-square"
              >
                <span className="label">YouTube</span>
              </a>
            </li>

            <li>
              <a
                href="https://www.snapchat.com/add/sameer_dingore"
                className="icon fa-snapchat"
              >
                <span className="label">Snapchat</span>
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/sam.dingore/"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
